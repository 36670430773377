const get = require('lodash/get');
const has = require('lodash/has');

try {
  const el = window.__NODE_CONFIG__ || document.getElementById('__NODE_CONFIG__');
  window.__NODE_CONFIG__ = JSON.parse((el && atob(el.textContent)) || process.env.NODE_CONFIG || null);
} catch (error) {
  console.error('Failed to integrate `config`');
}

/**
 * Browser behavior of `config` package.
 *
 * @deprecated Don't import `config` package on Client Components, instead pass values as props from `DI.AppConfig`.
 */
module.exports = {
  get: (path) => get(window.__NODE_CONFIG__, path),
  has: (path) => has(window.__NODE_CONFIG__, path),
};
